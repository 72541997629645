import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { AuthentikUser, UsersState } from './users.types';

const usersInitialState: UsersState = {
	users: {},
	selectedUserId: '',
};

export const UsersStore = signalStore(
	{ providedIn: 'root' },
	withState(usersInitialState),
	withComputed((store) => ({
		selectedUser: computed(() => {
			return store.users()[store.selectedUserId()] || null;
		}),
		usersList: computed(() => {
			return Object.keys(store.users()).map((key: string) => store.users()[key]);
		}),
	})),
	withMethods((store) => ({
		updateUser(userId: string, user: Partial<AuthentikUser>) {
			patchState(store, (state) => ({
				...state,
				users: {
					...state.users,
					[userId]: {
						...(state.users[userId] || {}),
						...user,
					},
				},
			}));
		},
		setSelectedUser(userId: string) {
			patchState(store, (state) => ({ ...state, selectedUserId: userId }));
		},
	})),
);
